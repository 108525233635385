import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { CurrentUser } from '../../App';
import requestClient from '../../utils/requestClient';
import { pillarMapper } from '../../utils/pillarMapper';

import { PurpleRelationshipsIcon } from '../../components/Icons';

import { LinkIcon, PhoneIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline'

const HelpAndSupport = () => {
    const {user} = useContext(CurrentUser)
    const [data, setData] = useState([]);

    const supportNumber = user.user.company.aep_phone_number ?? '03332128303'
    const supportTitle = user.user.company.aep_link_name ?? 'CALL A COUNSELOR'

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/helpandsupport/')

            let categories = {}

            response.data.data.useful_links.map(links => {
                if (!(links.pillar in categories)) {
                    categories[links.pillar] = []
                }
                categories[links.pillar].push(links)
            })

            setData(categories);
        }
        getData();
    }, []);

    return (user ? 
        <main>
            <section className='bg-white'>
                <div className='container py-8 lg:py-12'>
                    <Link to="/dashboard" className="flex items-center mb-8 lg:hidden">← DASHBOARD</Link>
                    <div className='grid lg:grid-cols-12'>
                        <div className='lg:col-span-8'>
                            <h1 className='mb-6'>Help and support</h1>
                            <div className='prose'>
                                <h2>Wellbeing Support line</h2>
                                <p>
                                    The Wellbeing Support Line provides free, confidential and impartial Practical Advice and Counselling Support. 
                                    This service is free for you to access whenever you need to with no need for permission from your organisation.
                                </p>
                                <p>
                                    It's simple, just click on the Wellbeing Line button in the OptiMe app to discuss anything that is troubling you.
                                </p>
                            </div>
                        </div>
                        <div className='lg:col-span-4 text-center'>
                            <PurpleRelationshipsIcon className="mx-auto" />
                            <h5 className="mt-6">{supportTitle}</h5>
                            <span className="btn btn-purple block lg:inline-block mt-6">{supportNumber}</span>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container py-8 lg:py-12'>
                    <div className='mb-8'>
                        <h2 className='mb-2'>Useful links</h2>
                        <p>Help and support from a range of resources which is recommended by OptiMe!</p>
                    </div>

                    <div className="grid gap-4 lg:grid-cols-2">
                        {Object.keys(data).map((category) => {
                            return (
                                <div key={category} className="bg-white flex items-center p-8 gap-4 lg:gap-8 flex-col text-center lg:text-left lg:flex-row">
                                    {pillarMapper(category).icon("mx-auto w-full max-w-[80px]")}
                                    <div className={'w-full'}>
                                        <h3 className='mb-2 capitalize text-center lg:text-left'>{pillarMapper(category).title}</h3>
                                        <ul className='grid gap-3 lg:grid-cols-2'>
                                            {data[category].map((category, index) => (
                                                <li key={index}>
                                                    <p className={'font-bold mb-1'}>{category.title}</p>
                                                    <div className={'flex text-xs break-all gap-2 justify-center lg:justify-start flex-wrap'}>
                                                        {category.link ? <a href={category.link} target="_blank" title={'Visit ' + category.link} className='flex items-center border border-purple py-1 px-2 rounded'><LinkIcon className={'h-4 w-4 mr-1'} /> Website</a> : null}
                                                        {category.phone_number ? <a href={'tel:' + category.phone_number} title={'Call ' + category.phone_number} className='flex items-center border border-purple py-1 px-2 rounded'><PhoneIcon className={'h-4 w-4 mr-1'} /> Call</a>: null}
                                                        {category.sms_number ? <a href={'tel:' + category.sms_number} title={'SMS ' + category.sms_number} className='flex items-center border border-purple py-1 px-2 rounded'><DevicePhoneMobileIcon className={'h-4 w-4 mr-1'} /> Text</a> : null}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        </main>
    : null);
};

export default HelpAndSupport;
